.gpt3__blog-container_article {
    width:270px;
    height:270px;
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem .5rem;
    text-align: center;
    height: 150px;
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25.11px;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content {
        height: 120px;
    }
    .gpt3__blog-container_article-content h3{
        font-size: 20px;
        line-height: 30px;
    }
}