section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gpt3__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    z-index: 99;
}

.gpt3__header-left {
    width: 30%;
}

.gpt3__header-left p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 50px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.berg__header-right {
    width: 30%;
}

.berg__header-right p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.typed-out {
    overflow: hidden;
    border-right: .15em solid #d39afc;
    white-space: nowrap;
    font-size: 1.6rem;
    letter-spacing: .15em;
    width: 0;
    animation: 
        typing 2s steps(20, end) forwards,
        blink-caret .75s step-end infinite;
}

.type-container {
    display: inline-block;
    margin: auto;
}

@keyframes typing {
    from { width: 0 }
    to { width:  100%}
}

@keyframes blink-caret {
    from, to { border-color: transparent}
    50% { border-color: #d39afc; }
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 75px;
    line-height: 100px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-text);
    margin-top: 1.5rem;
}


.gpt3__header-content p:last-child {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-text);
    margin-top: 1.5rem;
    text-align: center;
}

.berg__header-image img{
    margin-top: 2rem;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    border: 5px solid white
}

.berg__header-languages {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
}

.berg__header-languages img{
    padding: 12px;
    max-width: 80px;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }
    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .gpt3__header-left  {
        display: none;
    }
    .berg__header-right {
        display: none;
    }
}

@media screen and (max-width: 650px){
    .gpt3__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
    .berg__header-image img {
        max-width: 200px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

}

section .star {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    animation: animate 8s ease-in-out infinite;
}

section .star.star1 {
    animation-delay: 0s;
    background: url(../../assets/star1.png);
}

section .star.star2 {
    animation-delay: -1s;
    background: url(../../assets/star2.png);
}
.star.star3 {
    animation-delay: -2s;
    background: url(../../assets/star3.png);
}

section .star.star4 {
    animation-delay: -3s;
    background: url(../../assets/star4.png);
}

section .star.star5 {
    animation-delay: -4s;
    background: url(../../assets/star5.png);
}

section .star.star6 {
    animation-delay: -5s;
    background: url(../../assets/star6.png);
}
section .star.star7 {
    animation-delay: -6s;
    background: url(../../assets/star7.png);
}

section .star.star8 {
    animation-delay: -7s;
    background: url(../../assets/star8.png);
}

@keyframes animate {
    0%,20%,40%,60%,80%,100%
    {
        opacity: 0;
    }
    10%,30%,50%,70%,90%
    {
        opacity: 1;
    }
}