.gpt3__blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gpt3__blog-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 5rem;

}

.gpt3__blog-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gpt3__blog-container .gpt3__blog-container_article {
    border-radius: 10%;
}
