@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.gpt3__blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gpt3__blog-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 5rem;

}

.gpt3__blog-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gpt3__blog-container .gpt3__blog-container_article {
    border-radius: 10%;
}

.gpt3__blog-container_article {
    width:270px;
    height:270px;
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem .5rem;
    text-align: center;
    height: 150px;
}

.gpt3__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25.11px;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content {
        height: 120px;
    }
    .gpt3__blog-container_article-content h3{
        font-size: 20px;
        line-height: 30px;
    }
}
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
}

.gpt3__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font: 18px;
    line-height: 30px;
    letter-spacing: -0.04em;
    margin: 2rem 0;
    color: #fff;
}


@media  screen and (max-width: 550x) {
    .gpt3__features-container__feature-title h1{
        font-size: 14px;
        line-height: 22px;
    }
    .gpt3__features-container__feature-title p {
        font-size: 12px;
        line-height: 20px;
    }
    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
    
}
.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    height:50px;
    top: 0;
    z-index: 100;
    padding: 3rem 3rem;
    background: var(--color-header);
}

.gpt3__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links img {
    max-width: 40px
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container { 
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family); 
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-header);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}
.gpt3__navbar-menu_container-links-sign {
    display: none;
}
.gpt3__navbar-menu_container img {
    max-width: 30px;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar {
        background-color: transparent;
    }
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }
    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
.gpt3__subfeatures-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__subfeatures-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__subfeatures-container__feature-title h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font: 20px;
    line-height: 25px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__subfeatures-container__feature-title div{
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__subfeatures-container__feature-text {
    flex: 2 1;
    display: flex;
}

.gpt3__subfeatures-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: white;
}

@media  screen and (max-width: 550x) {
    .gpt3__subfeatures-container__feature-title h1{
        font-size: 12px;
        line-height: 20px;
    }
    .gpt3__subfeatures-container__feature-title p {
        font-size: 12px;
        line-height: 20px;
    }
    .gpt3__subfeatures-container__feature {
        margin: 1rem 0;
    }
    
}
.gpt3__blog-container_socials {
    width: 50%;
    height: 50%;
    max-width: 350px;
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 10%;
    background: var(--color-footer);
}

.gpt3__blog-container_socials-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_socials-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
}

.gpt3__blog-container_socials-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem .5rem;
    text-align: center;
    height: 75px;
}

.gpt3__blog-container_socials-content img{
    max-width: 50px;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_socials-content {
        height: 80px;
    }
    .gpt3__blog-container_socials-content h3{
        font-size: 12px;
        line-height: 18px;
    }
}
.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(purple);
}

.berg__footer-socials {
    display: flex;
    flex-direction: row;
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.gpt3__footer-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    letter-spacing: -0.04em;
}

.gpt3__footer-btn p{
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.gpt3__footer-links div{
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 1rem;
}

.gpt3__footer-links_div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    margin: .5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}

@media screen and (max-width: 850px){
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px){
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gpt3__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    z-index: 99;
}

.gpt3__header-left {
    width: 30%;
}

.gpt3__header-left p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 50px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.berg__header-right {
    width: 30%;
}

.berg__header-right p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.typed-out {
    overflow: hidden;
    border-right: .15em solid #d39afc;
    white-space: nowrap;
    font-size: 1.6rem;
    letter-spacing: .15em;
    width: 0;
    -webkit-animation: 
        typing 2s steps(20, end) forwards,
        blink-caret .75s step-end infinite;
            animation: 
        typing 2s steps(20, end) forwards,
        blink-caret .75s step-end infinite;
}

.type-container {
    display: inline-block;
    margin: auto;
}

@-webkit-keyframes typing {
    from { width: 0 }
    to { width:  100%}
}

@keyframes typing {
    from { width: 0 }
    to { width:  100%}
}

@-webkit-keyframes blink-caret {
    from, to { border-color: transparent}
    50% { border-color: #d39afc; }
}

@keyframes blink-caret {
    from, to { border-color: transparent}
    50% { border-color: #d39afc; }
}

.gpt3__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 75px;
    line-height: 100px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-text);
    margin-top: 1.5rem;
}


.gpt3__header-content p:last-child {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-text);
    margin-top: 1.5rem;
    text-align: center;
}

.berg__header-image img{
    margin-top: 2rem;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    border: 5px solid white
}

.berg__header-languages {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
}

.berg__header-languages img{
    padding: 12px;
    max-width: 80px;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }
    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    .gpt3__header-left  {
        display: none;
    }
    .berg__header-right {
        display: none;
    }
}

@media screen and (max-width: 650px){
    .gpt3__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
    .berg__header-image img {
        max-width: 200px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

}

section .star {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    -webkit-animation: animate 8s ease-in-out infinite;
            animation: animate 8s ease-in-out infinite;
}

section .star.star1 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    background: url(/static/media/star1.625e6cad.png);
}

section .star.star2 {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: url(/static/media/star2.da5717ac.png);
}
.star.star3 {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: url(/static/media/star3.3b700035.png);
}

section .star.star4 {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: url(/static/media/star4.f8356fb9.png);
}

section .star.star5 {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: url(/static/media/star5.802c13cc.png);
}

section .star.star6 {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    background: url(/static/media/star6.947906d8.png);
}
section .star.star7 {
    -webkit-animation-delay: -6s;
            animation-delay: -6s;
    background: url(/static/media/star7.77eac06e.png);
}

section .star.star8 {
    -webkit-animation-delay: -7s;
            animation-delay: -7s;
    background: url(/static/media/star8.fdbea80c.png);
}

@-webkit-keyframes animate {
    0%,20%,40%,60%,80%,100%
    {
        opacity: 0;
    }
    10%,30%,50%,70%,90%
    {
        opacity: 1;
    }
}

@keyframes animate {
    0%,20%,40%,60%,80%,100%
    {
        opacity: 0;
    }
    10%,30%,50%,70%,90%
    {
        opacity: 1;
    }
}
.gpt3__resume {
    display: flex;
    flex-direction: column;

    padding: 4rem;
    background: var(--color-footer);
    border-radius: 25px;

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.berg__resume-feature {
    text-align: center;
    margin: auto;
}

.gpt3__resume-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    
    margin: 4rem 0 2rem;
}

.gpt3__resume-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__resume-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    text-decoration: underline;
    color: white;
    cursor: pointer;
}

.gpt3__resume-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__resume-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: auto;
    max-width: 200px;
    text-align: center;
    display: unset;
    flex-direction: column;
}


@media screen and (max-width: 850px) {
    .gpt3__resume-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__resume-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__resume-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__resume-feature .gpt3__features-container__feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__resume-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__resume-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body{
  background: var(--color-bg);
}

.normal_bg {
  padding-top: 20px;
  background: var(--color-bg);
}

a{
  color: unset;
  text-decoration: none;
}

.gradient_bg{

  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

}

.gradient__text {
  background:var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.grow1 {
  transition: all .2s ease-in-out;
}

.grow1:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px){
  .resume{
    display: none;
  }
  .section__padding{
    padding: 4rem;
  }

  .section__margin{
    margin: 4rem;
  }
}

@media screen and (max-width: 550px){
  .section__padding{
    padding: 4rem 2rem;
  }

  .section__margin{
    margin: 4rem 2rem;
  }
}

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #94067f 1.84%, #d39afc 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #94067f -13.86%, #d39afc  99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-header: #81AFDD;
  --color-text: white;
  --color-subtext: #FF8A71;
}
