.gpt3__subfeatures-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__subfeatures-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__subfeatures-container__feature-title h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font: 20px;
    line-height: 25px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__subfeatures-container__feature-title div{
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__subfeatures-container__feature-text {
    flex: 2;
    display: flex;
}

.gpt3__subfeatures-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: white;
}

@media  screen and (max-width: 550x) {
    .gpt3__subfeatures-container__feature-title h1{
        font-size: 12px;
        line-height: 20px;
    }
    .gpt3__subfeatures-container__feature-title p {
        font-size: 12px;
        line-height: 20px;
    }
    .gpt3__subfeatures-container__feature {
        margin: 1rem 0;
    }
    
}