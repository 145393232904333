.gpt3__blog-container_socials {
    width: 50%;
    height: 50%;
    max-width: 350px;
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 10%;
    background: var(--color-footer);
}

.gpt3__blog-container_socials-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_socials-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
}

.gpt3__blog-container_socials-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem .5rem;
    text-align: center;
    height: 75px;
}

.gpt3__blog-container_socials-content img{
    max-width: 50px;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_socials-content {
        height: 80px;
    }
    .gpt3__blog-container_socials-content h3{
        font-size: 12px;
        line-height: 18px;
    }
}